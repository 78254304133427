<template>
  <div>
    <feather-icon title="Switch" icon="ExternalLinkIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onChangeActiveApplication" />
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onChangeActiveApplication () {
      this.params.context.componentParent.handleChangeActiveApplication(this.params.data) 
    },
  }
}
</script>
