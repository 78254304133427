<template>
  <travio-center-container grid-width='2/3'>
    <h2 style="color:#636363" class="mb-4">All Companies and Apps</h2>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input
            class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
       <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="companyList"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="true"
        :context="context"
        :suppressPaginationPanel="false"
      >
      </ag-grid-vue>
      
      
    </vx-card>
  
  </travio-center-container>
</template>

<script>
    
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import CellRendererStripeIdLink from './cell-renderers/CellRendererStripeIdLink.vue'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererStripeIdLink,
  },
  props: {
  },
  data () {
    return {
      companyList: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActions,
        CellRendererStripeIdLink,
      },
      context: null,
      searchQuery: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {

    this.$vs.loading()
    try {
      const response = await this.$http.get(`api/admin/companies/userAdmin`)
      this.companyList = response.data

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  beforeMount() {

    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Company Id', field: 'companyId', width: 120,  suppressSizeToFit: false },
      { headerName: 'Company Name', field: 'companyName', width: 220,  suppressSizeToFit: false },
      { headerName: 'App Id', field: 'applicationId', width: 120,  suppressSizeToFit: false },
      { headerName: 'App Name', field: 'applicationName', width: 220,  suppressSizeToFit: false },
      { headerName: 'Stripe Cust ID', width: 180, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererStripeIdLink) },
      { headerName: 'Switch', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    handleChangeActiveApplication (data) {
      this.$vs.loading();

      this.$store.commit('UPDATE_APP_ID_TO_SWITCH', data.applicationId)
      this.$store.commit('UPDATE_SKIP_REDIRECT_AFTER_APP_SWITCH', false)
      this.$store.commit('UPDATE_ACTIVE_COMPANY', { id: data.companyId, name: data.companyName })
      
      this.$store.dispatch('auth/switchCompany', { companyId: data.companyId })
      .then(response => {
        return this.$store.dispatch('updateUserInfoExplicitCompany', { companyId: data.companyId } );
      })
      .then(response => {
        const activeUser = this.$store.state.AppActiveUser;
        this.$store.commit('UPDATE_APPLICATION_LIST', activeUser.applications)
        this.$store.commit('UPDATE_COMPANY_LIST', activeUser.companies)

        let activeAppId = activeUser.applications[0].id
        let activeAppName = activeUser.applications[0].name
        let activeAppFeatures = activeUser.applications[0].features

        let activeApp = null;
        if(this.$store.state.SwitchToApplicationId) {
          activeApp = activeUser.applications.find(x => x.id == this.$store.state.SwitchToApplicationId)
          activeAppId = activeApp.id
          activeAppName = activeApp.name
          activeAppFeatures = activeApp.features
          this.$store.commit('UPDATE_APP_ID_TO_SWITCH', 0)
        }

        this.$store.commit('UPDATE_ACTIVE_APP', {
          id: activeAppId, name: activeAppName,
          features: activeAppFeatures || [], 
          appStatsUrl: activeApp && activeApp.appStatsUrl || null,
          appStatsUrlLink: activeApp && activeApp.appStatsUrlLink || null
        })
        
        this.$router.push({ name: 'home' }) 
        // this.$router.go()
        // location.reload()
      })
      .catch(error => console.log(error))
      .finally(()=> this.$vs.loading.close())

    },
  }
}
</script>

<style>

</style>