<template>
  <div>
    <a :href="stripeCustUrl" class="announcement-link" target="_blank">{{this.params.data.stripeCustId}}</a>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      showDeletePopup: false
    }
  },

  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    stripeCustUrl() {
      return (this.params.data.stripeCustId && `https://dashboard.stripe.com/customers/${this.params.data.stripeCustId}`)
    }
  },
  methods: {
    onChangeActiveApplication () {
      this.params.context.componentParent.handleChangeActiveApplication(this.params.data) 
    },
  }
}
</script>
